import React from "react"

import Layout from "../../components/layout"
import Hero from "../../components/Hero"
import { PeopleHunt } from "../../components/PeopleHunt"
import { Contact } from "../../components/Contact"
import SEO from "../../components/seo"
import ReferenceContainer from "../../components/ReferenceContainer"

const Identity = () => {
  const tags = [
    "IAM",
    "IAG",
    "MIM",
    "Microsoft Identity Manager",
    "MIM2016",
    "SailPoint",
    "OneIdentity",
    "Provisioning",
    "SSO",
    "BankID",
    "MFA",
    "O365",
    "Federation",
    "AD",
    "Onboarding",
    "Offboarding",
    "AAD",
    "AAD Connect",
    "Azure AD",
  ]

  return (
    <Layout>
      <SEO title="Identity" />
      <Hero heading={`Identity`} goto="references" scrollTitle="Identity" />
      <ReferenceContainer></ReferenceContainer>
      <PeopleHunt />
      <Contact />
    </Layout>
  )
}

export default Identity
